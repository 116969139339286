import type { IProductSubCategory } from 'types/Product';

/**
 * function that calculates the number of products within a subcategory.
 * @param subCategory the subcategory that contains references to PiMProductCategories
 * @returns number of products
 */
export const getProductCategoriesAmount = (subCategory: IProductSubCategory): number =>
  subCategory?.productCategories.reduce(
    (sum: number, productCategory: any) => sum + productCategory?.products?.length || 0,
    0,
  ) || 0;
