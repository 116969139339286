/**
 * Note these filters are for non-algolia search results
 * Algolia search result filter data is compiled through the use of facets
 */
import type { ProductGridProduct } from 'types/Product';

export type FilterOption = {
  key: string;
  label: string;
  selected: boolean;
  itemCount: number;
};

export type FilterTopic = {
  key: string;
  label: string;
  options: FilterOption[];
  type: 'text' | 'rating';
};

export enum FilterItemType {
  ANY,
  PRODUCT,
  RECIPE,
}

const getProductFilterOptions = (
  items: any[],
  labels: Record<string, string>,
): Record<string, FilterOption[]> => {
  const subBrands: Record<string, number> = {};
  const dietary: Record<string, number> = {};
  for (let i = 0; i < items.length; i++) {
    const currentItem = items[i];
    if (currentItem?.subBrand) {
      const sb = currentItem?.subBrand;
      if (!subBrands[sb]) {
        subBrands[sb] = 1;
      } else {
        subBrands[sb] += 1;
      }
    }
    if (currentItem.productDietList?.length > 0) {
      currentItem.productDietList.forEach((a: string) => {
        if (!dietary[a]) {
          dietary[a] = 1;
        } else {
          dietary[a] += 1;
        }
      });
    }
  }
  return {
    subBrandOptions: Object.keys(subBrands).map((sb) => ({
      key: `subbrand_${sb}`,
      label: sb,
      selected: false,
      itemCount: subBrands[sb],
    })),
    dietaryOptions: Object.keys(dietary).map((a) => ({
      key: `dietary_${a}`,
      label: labels[a],
      selected: false,
      itemCount: dietary[a],
    })),
  };
};

export const getFiltersList = (
  itemType: FilterItemType,
  items: any[],
  labels: Record<string, string>,
): FilterTopic[] => {
  // PRODUCTS
  if (itemType === FilterItemType.PRODUCT) {
    const { subBrandOptions, dietaryOptions } = getProductFilterOptions(items, labels);
    const productFilters = [];
    if (subBrandOptions.length > 0) {
      productFilters.push({
        key: 'sub-brand',
        label: labels.filterSubBrand || 'Sub Brand',
        options: subBrandOptions,
        type: 'text',
      } as FilterTopic);
    }
    if (dietaryOptions.length > 0) {
      productFilters.push({
        key: 'dietary',
        label: labels.filter_diet_list || 'Dietary',
        options: dietaryOptions,
        type: 'text',
      } as FilterTopic);
    }
    return productFilters;
  }
  // RECIPES
  if (itemType === FilterItemType.RECIPE) {
    return [];
  }
  return [];
};

type FilterableItem = ProductGridProduct;

export function filterItems(
  itemType: FilterItemType,
  items: FilterableItem[],
  filters: FilterTopic[],
): FilterableItem[] {
  if (itemType === FilterItemType.PRODUCT) {
    const matchingSubBrands: string[] = [];
    const matchingDietary: string[] = [];
    filters.forEach((f) =>
      f.options.forEach((o) => {
        if (o.selected && f.key === 'sub-brand')
          matchingSubBrands.push(o.key.replace('subbrand_', ''));
        if (o.selected && f.key === 'dietary') matchingDietary.push(o.key.replace('dietary_', ''));
      }),
    );
    return (items as ProductGridProduct[]).filter((itm) => {
      if (matchingSubBrands.length === 0 && matchingDietary.length === 0) return true;
      let itmMatches = false;
      if (matchingSubBrands.length > 0 && matchingSubBrands.includes(itm?.subBrand || '')) {
        itmMatches = true;
      }
      if (
        matchingDietary.length > 0 &&
        itm.productDietList.reduce(
          (hasMatch: boolean, allergen: string) => matchingDietary.includes(allergen) || hasMatch,
          false,
        )
      ) {
        itmMatches = true;
      }

      return itmMatches;
    });
  }
  return items;
}
