import { IProductSubCategory } from 'types/Product';
import { getProductCategoriesAmount } from '../getProductCategoriesAmount';

/**
 * function that calculates the number of products within a subcategory and its sub-subcategories
 * @param subCategory subcategory to calculate the number of products of
 * @returns the number of products
 */
export const getProductsAmount = (subCategory: IProductSubCategory) => {
  // TODO: added any to the type because not all the pages are correctly typed.
  // they need to be fixed first
  let productsAmount = getProductCategoriesAmount(subCategory);
  productsAmount += subCategory?.subSubcategoryPages?.reduce(
    (sum: number, subCategoryPage: any) => {
      const productLength = getProductCategoriesAmount(subCategoryPage);
      return sum + productLength;
    },
    0,
  );
  return productsAmount || 0;
};
